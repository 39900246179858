import { HEADER_HEIGHT, MENU_WIDTH } from '@constants/constants';
import { Button, Drawer } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Theme, alpha, styled } from '@mui/material/styles';

interface ListButtonProps {
  theme: Theme;
  active: boolean;
}

export const Nav = styled(Drawer)(({ theme }) => ({
  width: MENU_WIDTH,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(3),
  position: 'fixed',
  top: HEADER_HEIGHT,
  bottom: 0,
  alignSelf: 'flex-start',
  '& .MuiDrawer-paper': {
    width: MENU_WIDTH,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(5),
    backgroundColor: theme.palette.grey[200],
    position: 'fixed',
    bottom: 0,
    alignSelf: 'flex-start',
    border: 'none',
    overflow: 'visible',
  },
  '&.MuiDrawer-docked .MuiDrawer-paper': {
    top: HEADER_HEIGHT,
    paddingTop: theme.spacing(12),
  },
}));

export const StyledListButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }: ListButtonProps) => ({
  borderRadius: theme.shape.borderRadius * 1.5,
  paddingTop: 10,
  paddingBottom: 10,
  whiteSpace: 'nowrap',
  marginBottom: theme.spacing(1.5),
  ...(active && {
    backgroundColor: alpha(theme.palette.grey[800], 0.12),
  }),
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  overflow: 'hidden',
  height: 24,
  width: 24,
  marginRight: theme.spacing(1.5),
  justifyContent: 'center',
  color: theme.palette.grey[800],
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: `-${theme.spacing(10)}`,
  padding: '10px',
  background: 'transparent',
  color: theme.palette.common.white,
  minWidth: 0,
  borderRadius: theme.shape.borderRadius * 1.5,
}));
